import { default as abuseReportsSumvHo3ZGGMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93L8zT6ioN24Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index5l3mG1bolcMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/index.vue?macro=true";
import { default as moderationI7pPrzXn9WMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/moderation.vue?macro=true";
import { default as pendingBans4Xshb8z7Z1Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/pendingBans.vue?macro=true";
import { default as profilesdGrGbJEntlMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/profiles.vue?macro=true";
import { default as expensesKhzcyUaDVDMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/expenses.vue?macro=true";
import { default as index4hjvjbMVqBMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/index.vue?macro=true";
import { default as overview0qT8TaY6PIMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingg8iX1jRY0dMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingfGnsXR3zpoMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/translations/missing.vue?macro=true";
import { default as usersabrAenngIDMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/users.vue?macro=true";
import { default as apiEMhcJSZoNZMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/api.vue?macro=true";
import { default as _91slug_93LBXTQc9neYMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/blog/[slug].vue?macro=true";
import { default as indexvR9AOGEfT9Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_9356tKxam3uJMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93gGj6qrJrhVMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminbulD2lfM2FMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/census/admin.vue?macro=true";
import { default as indexL540qIpqfhMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/census/index.vue?macro=true";
import { default as contactZWdTKjnkiTMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/contact.vue?macro=true";
import { default as designu3eN8xOBCYMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/design.vue?macro=true";
import { default as englishZsrmgotNFRMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/english.vue?macro=true";
import { default as faqN7YA56xwCqMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/faq.vue?macro=true";
import { default as inclusiveCTDiFZG5vEMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/inclusive.vue?macro=true";
import { default as indexwTLyJhLsO9Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/index.vue?macro=true";
import { default as licensec3tsoxzkyeMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/license.vue?macro=true";
import { default as academicD3jbVXsGjkMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/academic.vue?macro=true";
import { default as indexxnraDBvLvUMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/index.vue?macro=true";
import { default as mediazEzONXTnQzMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/media.vue?macro=true";
import { default as translinguisticsahx7hExHULMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/translinguistics.vue?macro=true";
import { default as zineMXiNu9lS5dMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/zine.vue?macro=true";
import { default as namesF9c0cmzD1SMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/names.vue?macro=true";
import { default as indexWaiemMmmZoMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/nouns/index.vue?macro=true";
import { default as templatesi4P2KQv7boMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/nouns/templates.vue?macro=true";
import { default as peopleT409Q9tgBjMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/people.vue?macro=true";
import { default as privacyjHduSbfl2UMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/privacy.vue?macro=true";
import { default as _91username_9369nG1KRCKDMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_931B0vNnB57kMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/card-[username].vue?macro=true";
import { default as editornhS1tSIZj8Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/editor.vue?macro=true";
import { default as anyXlbgsBamJAMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/any.vue?macro=true";
import { default as avoidingY6ScyNDGsJMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/avoiding.vue?macro=true";
import { default as indexcTyio0XdeVMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/index.vue?macro=true";
import { default as mirrorVS8z2gOieJMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/mirror.vue?macro=true";
import { default as pronounNjHJhVOnyJMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesN2YI68tq46Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/sources.vue?macro=true";
import { default as teamSBIyrfG7cmMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/team.vue?macro=true";
import { default as terminologyb3zvt2FMt7Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/terminology.vue?macro=true";
import { default as termsBBUTvE8ad8Meta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/terms.vue?macro=true";
import { default as userlu5DcC2iaRMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/user.vue?macro=true";
import { default as workshopsmVCEhJc9PKMeta } from "/home/admin/www/en.pronouns.page/release/20241015135747/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsSumvHo3ZGGMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans4Xshb8z7Z1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiEMhcJSZoNZMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93LBXTQc9neYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexvR9AOGEfT9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_9356tKxam3uJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93gGj6qrJrhVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminbulD2lfM2FMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexL540qIpqfhMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactZWdTKjnkiTMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishZsrmgotNFRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqN7YA56xwCqMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveCTDiFZG5vEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicD3jbVXsGjkMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexxnraDBvLvUMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediazEzONXTnQzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsahx7hExHULMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineMXiNu9lS5dMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesF9c0cmzD1SMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexWaiemMmmZoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesi4P2KQv7boMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleT409Q9tgBjMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyjHduSbfl2UMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_9369nG1KRCKDMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_931B0vNnB57kMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editornhS1tSIZj8Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyXlbgsBamJAMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingY6ScyNDGsJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexcTyio0XdeVMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorVS8z2gOieJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounNjHJhVOnyJMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesN2YI68tq46Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamSBIyrfG7cmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyb3zvt2FMt7Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsBBUTvE8ad8Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userlu5DcC2iaRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsmVCEhJc9PKMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241015135747/pages/workshops.vue")
  }
]